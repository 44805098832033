// import { useEffect, useRef } from "react";
// import "./VideoComponent.css";

// function VideoComponent({ track, participantIdentity, local = false }) {
//     const videoElement = useRef(null);

//     useEffect(() => {
//         if (videoElement.current) {
//             // Ensure that the track is attached to the video element
//             track.attach(videoElement.current);
//         }

//         return () => {
//             // Detach the track when the component unmounts or when the track changes
//             track.detach();
//         };
//     }, [track]);

//     return (
//         <div id={"camera-" + participantIdentity} className="video-container">
//             <div className="participant-data">
//                 <p>{participantIdentity + (local ? " (You)" : "")}</p>
//             </div>
//             {/* Add autoPlay and playsInline for better performance, muted for local video */}
//             <video 
//                 ref={videoElement} 
//                 id={track.sid} 
//                 autoPlay 
//                 playsInline 
//                 muted={local} // Mute the local participant’s video
//             ></video>
//         </div>
//     );
// }

// export default VideoComponent;


// v2

import "./VideoComponent.css";
import { useEffect, useRef } from "react";

function VideoComponent({ track, participantIdentity, local = false, onVideoClick }) {
  const videoElement = useRef(null);

  useEffect(() => {
    if (videoElement.current) {
      track.attach(videoElement.current);
    }

    return () => {
      track.detach();
    };
  }, [track]);

  return (
    <div id={"camera-" + participantIdentity} className="video-container" onClick={() => onVideoClick(videoElement.current)}>
      <div className="participant-data">
        <p>{participantIdentity + (local ? " (You)" : "")}</p>
      </div>
      <video ref={videoElement} id={track.sid}></video>
    </div>
  );
}

export default VideoComponent;


// v3


// import "./VideoComponent.css";
// import { useEffect, useRef } from "react";

// function VideoComponent({ track, participantIdentity, local = false, onVideoClick , isVideoEnabled}) {
//   const videoElement = useRef(null);

//   useEffect(() => {
//     if (videoElement.current) {
//       track.attach(videoElement.current);
//     }

//     return () => {
//       track.detach();
//     };
//   }, [track]);

//   return (
//     <div id={"camera-" + participantIdentity} className="video-container"  onClick={() => onVideoClick(videoElement.current)} >
//       <div className="participant-data">
//         <p>{participantIdentity + (local ? " (You)" : "")}</p>
//       </div>
//       <video ref={videoElement} id={track.sid} ></video>
//     </div>
//   );


//   //   return (
//   //   <div id={"camera-" + participantIdentity} className="video-container" >
//   //     {track ? (
//   //       <div className="participant-data" >
//   //         <p>{participantIdentity + (local ? " (You)" : "")}</p>
//   //         <video ref={videoElement} id={track.sid} autoPlay playsInline muted={local}></video>
//   //       </div>
//   //     ) : (
//   //       <div className="participant-data-2" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>
//   //         <p>{participantIdentity + (local ? "" : "")}</p>
//   //       </div>
//   //     )}
//   //   </div>
//   // );
// }

// export default VideoComponent;



// import "./VideoComponent.css";
// import { useEffect, useRef } from "react";

// function VideoComponent({ track, participantIdentity, local = false , isVideoEnabled}) {
//   const videoElement = useRef(null);

//   // useEffect(() => {
//   //   if (videoElement.current) {
//   //     track.attach(videoElement.current);
//   //   }

//   //   return () => {
//   //     track.detach();
//   //   };
//   // }, [track]);

//   useEffect(() => {
   
//     if (videoElement.current && isVideoEnabled) {
//       track.attach(videoElement.current);
//     } else if (videoElement.current && !isVideoEnabled) {
//       track.detach();
//     }

//     return () => {
//       track?.detach();
//     };
//   }, [track, isVideoEnabled]);

//   return (
//     <div id={"camera-" + participantIdentity} className="video-container" >
//       {isVideoEnabled && track ? (
//         <div className="participant-data" >
//           {/* <p>{participantIdentity + (local ? " (You)" : "")}</p> */}
//           <video ref={videoElement} id={track.sid} autoPlay playsInline muted={local}></video>
//         </div>
//       ) : (
//         <div className="participant-data-2" style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>
//           <p>{participantIdentity + (local ? "" : "")}</p>
//         </div>
//       )}
//     </div>
//   );
// }

// export default VideoComponent;

