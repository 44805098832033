// import { useEffect, useRef } from "react";
// import "./VideoComponent.css";

// function VideoComponent({ track, participantIdentity, local = false , isVideoEnabled}) {
//     const videoElement = useRef(null);

//     useEffect(() => {
//         if (track && videoElement.current) {
//             // Attach the track to the video element
//             track.attach(videoElement.current);
//         }

//         return () => {
//             // Detach the track when the component unmounts or the track changes
//             if (track) {
//                 track.detach();
//             }
//         };
//     }, [track]);

//     return (
//         <div id={"camera-" + participantIdentity} className="video-container">
//             {track ? (
//                 <>
//                     {/* Render the video element if the track is available */}
                    
//                     <video
//                         ref={videoElement}
//                         id={track.sid}
//                         autoPlay
//                         playsInline
//                         muted={local} // Mute local participant’s video
//                     ></video> 
                   
//                     {/* Participant name at top-left */}
                  
//                     <div className="participant-data top-left">
//                         <p>{participantIdentity + (local ? " (You)" : "")}</p>
//                     </div>
                     
//                 </>
//             ) : (
//                 // Render the participant name in the center if no video track
//                 <div className="no-video-placeholder">
//                     <p>{participantIdentity + (local ? " (You)" : "")}</p>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default VideoComponent;





// before final

// import { useEffect, useRef } from "react";
// import "./VideoComponent.css";

// function VideoComponent({ track, participantIdentity, local = false }) {
//   const videoElement = useRef(null);

//   useEffect(() => {
//     if (track && videoElement.current) {
//       // Attach the track to the video element only if video is enabled
//       track.attach(videoElement.current);
//     } else if (track) {
//       // Detach the track if video is disabled or track changes
//       track.detach();
//     }

//     return () => {
//       if (track) {
//         track.detach();
//       }
//     };
//   }, [track]);

//   return (
//     <div id={"camera-" + participantIdentity} className="video-container">
//       {track ? (
//         <>
//           {/* Render the video element if the track is available and video is enabled */}
//           <video
//             ref={videoElement}
//             id={track.sid}
//             autoPlay
//             playsInline
//             muted={local} // Mute local participant’s video
//           />

//           {/* Participant name at top-left */}
//           <div className="participant-data top-left">
//             <p>{participantIdentity + (local ? " (You)" : "")}</p>
//           </div>
//         </>
//       ) : (
//         // Render the participant name in the center if no video track or video is disabled
//         <div className="no-video-placeholder">
//           <p>{participantIdentity + (local ? " (You)" : "")}</p>
//         </div>
//       )}
//     </div>
//   );
// }

// export default VideoComponent;





// import { useEffect, useRef, useState } from "react";
// import "./VideoComponent.css";

// function VideoComponent({ track, participantIdentity, local = false , onVideoClick}) {
//   const videoElement = useRef(null);
//   const [isTrackActive, setIsTrackActive] = useState(!!track); // Track activity state

//   useEffect(() => {
//     if (track && videoElement.current) {
//       // Attach the track to the video element only if video is enabled
//       track.attach(videoElement.current);
//       setIsTrackActive(true);
//     } else {
//       // Detach the track if video is disabled or track changes
//       if (track) {
//         track.detach();
//       }
//       setIsTrackActive(false); // Update state to reflect inactive track
//     }

//     return () => {
//       if (track) {
//         track.detach();
//       }
//     };
//   }, [track]);

//   // Don't render the component if the track is not active
//   if (!isTrackActive) {
//     return null;
//   }

//   return (
//     <div id={"camera-" + participantIdentity} className="video-container"  onClick={() => onVideoClick(videoElement.current)}>
//       <video
//         ref={videoElement}
//         id={track?.sid}
//         autoPlay
//         playsInline
//         muted={local} // Mute local participant’s video
//       />

//       {/* Participant name at top-left */}
//       <div className="participant-data top-left">
//         <p>{participantIdentity + (local ? " (You)" : "")}</p>
//       </div>
//     </div>
//   );
// }

// export default VideoComponent;


import { useEffect, useRef, useState } from "react";
import "./VideoComponent.css";

function VideoComponent({ track, participantIdentity, local = false ,onVideoClick}) {
  const videoElement = useRef(null);
  const [isTrackActive, setIsTrackActive] = useState(false); 

  useEffect(() => {
    if (track && videoElement.current && !track.isMuted) {
      // Attach the track to the video element only if it's not muted
      try {
        track.attach(videoElement.current);
        setIsTrackActive(true);
        console.log("Video track attached for:", participantIdentity);
      } catch (error) {
        console.error("Error attaching video track:", error);
        setIsTrackActive(false);
      }
    } else {
      // Detach the track if muted or track changes
      if (track) {
        track.detach();
        console.log("Video track detached for:", participantIdentity);
      }
      setIsTrackActive(false); // Update state to reflect inactive track
    }

    // Cleanup on component unmount
    return () => {
      if (track) {
        track.detach();
        console.log("Cleaning up video track for:", participantIdentity);
      }
    };
  }, [participantIdentity, track]);

  // Render placeholder if the track is inactive or muted
  if (!isTrackActive || track?.isMuted) {
    return (
      <div
        className="video-container no-video"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#1B1B1BFF", // Placeholder background
        }}
      >
        <p style={{ fontSize: "16px", color: "#666" }}>
          {participantIdentity + (local ? " (You)" : "")}
        </p>
      </div>
    );
  }

  // Render the video component when the track is active
  return (
    <div id={"camera-" + participantIdentity} className="video-container"  onClick={() => onVideoClick(videoElement.current)}>
      <video
        ref={videoElement}
        id={track?.sid}
        autoPlay
        playsInline
        muted={local} // Mute local participant’s video
      />

      {/* Participant name at top-left */}
      <div className="participant-data top-left">
        <p>{participantIdentity + (local ? " (You)" : "")}</p>
      </div>
    </div>
  );
}

export default VideoComponent;


